<template>
  <component :is="link ? AsfLink : 'div'" v-bind="attributes" class="asf-logo">
    <slot>
      <AsfImage :src="image" :alt="`${attributes.title}`" :title="`${attributes.title}`" width="200" height="50" />
    </slot>
  </component>
</template>
<script lang="ts" setup>
import type { AsfLogoProps } from '@ui/types'
import { AsfLink } from '#components'
import image from 'assets/images/logo.svg'
const props = defineProps<AsfLogoProps>()
const { t } = useI18n()

const attributes = computed(() => ({
  link: props.link || null,
  title: props.title || t('logo.link')
}))
</script>
<style lang="postcss">
@import '@components/molecules/Logo/Logo.css';
</style>
