<script lang="ts" setup>
import type { AmplienceContent } from '../types'
import type { ContentSlotProps } from 'shared/types'
import { CheckoutFooterNavigation, FooterBenefits, FooterFeatures, FooterNavigation, FooterSocials } from '#components'

const componentsMap: Record<string, any> = {
  CheckoutFooterNavigation,
  FooterBenefits,
  FooterFeatures,
  FooterNavigation,
  FooterSocials
}
defineProps<ContentSlotProps<AmplienceContent>>()
</script>

<template>
  <div class="asf-content-footer">
    <template v-for="(cmp, idx) in content" :key="idx">
      <component :is="componentsMap[cmp.componentName]" v-bind="cmp" />
    </template>
  </div>
</template>
