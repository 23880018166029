<template>
  <div class="asf-secure-icon">
    <AsfIcon id="secure-icon" name="check-shield" />
    <span v-if="title" class="asf-secure-icon__text">{{ title }}</span>
  </div>
</template>
<script lang="ts">
import type { AsfSecureIconProps } from '@ui/types'

export default defineComponent({
  name: 'AsfSecureIcon',
  props: {
    /** Defines message located on the right from the icon */
    title: { type: String as PropType<AsfSecureIconProps['title']>, default: `` }
  }
})
</script>
<style lang="postcss">
@import '@components/molecules/SecureIcon/SecureIcon.css';
</style>
