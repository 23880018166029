<template>
  <div
    v-e2e="'notification'"
    class="asf-notification"
    @mouseover="clearNotificationTimeout"
    @mouseout="hideNotification"
  >
    <AsfAlert :appearance="appearance">
      <slot />
    </AsfAlert>
  </div>
</template>
<script lang="ts" setup>
import type { AsfNotificationProps } from '@ui/types'
const props = withDefaults(defineProps<AsfNotificationProps>(), {
  appearance: 'default',
  displayTime: 4
})
const emit = defineEmits(['notification:hide'])

let timerId: ReturnType<typeof setTimeout> | undefined
const timeout = computed(() => (props.displayTime || 1) * 1000)

const hideNotification = () => {
  timerId = setTimeout(() => emit('notification:hide'), timeout.value)
}

const clearNotificationTimeout = () => timerId && clearTimeout(timerId)

onMounted(hideNotification)
onBeforeUnmount(clearNotificationTimeout)
</script>
<style lang="postcss">
@import '@components/molecules/Notification/Notification.css';
</style>
