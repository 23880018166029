import type { FocusableElement } from 'shared/directives'
import { AsfKeyValue } from '@ui/types'

export const useFocusHighlighter = (
  pageLayoutRef: Ref<HTMLDivElement | null>,
  highlighterRef: Ref<HTMLDivElement | null>
) => {
  const gutter = {
    position: 0,
    size: 0
  }
  const lastFocused = ref<FocusableElement | null>(null)
  const lastFocusedCoords = ref<string>('')
  const lastKeyTime = ref<number>(0)
  const isKeyboardModality = ref<boolean>(false)
  const isVisible = ref<boolean>(false)
  const isSpeedUp = ref<boolean>(false)

  const isValidTarget = (domNode: FocusableElement) => {
    return domNode !== lastFocused.value && domNode.nodeName !== 'HTML' && domNode.nodeName !== 'BODY'
  }
  const isTextInput = (domNode: FocusableElement): boolean => {
    const { tagName, readOnly } = domNode as HTMLInputElement | HTMLTextAreaElement
    const isTextField = tagName === 'TEXTAREA' || tagName === 'INPUT'

    return (isTextField && !readOnly) || Boolean(domNode.getAttribute('contenteditable'))
  }
  const isFocusHighlighterEnabled = (domNode: HTMLElement): boolean => {
    return domNode.hasAttribute('data-keep-focus-highlighter')
  }
  const detectSpeedUp = () => {
    const currentTime = Date.now()

    isSpeedUp.value = currentTime - (lastKeyTime.value || 0) < 190
    lastKeyTime.value = currentTime
  }
  const moveTo = (focusedElement: FocusableElement): void => {
    if (!(focusedElement instanceof HTMLElement)) return

    if (!highlighterRef.value) return

    const targetRectangle = focusedElement.getBoundingClientRect()
    const targetTop = targetRectangle.top + window.scrollY
    const targetLeft = targetRectangle.left + window.scrollX
    const targetWidth = focusedElement.offsetWidth
    const targetHeight = focusedElement.offsetHeight

    if (
      focusedElement === lastFocused.value &&
      lastFocusedCoords.value === String(targetTop + targetLeft + targetWidth + targetHeight)
    ) {
      return
    }

    const highlighterStyle = highlighterRef.value.style

    requestAnimationFrame(() => {
      highlighterStyle.top = `${targetTop - gutter.position}px`
      highlighterStyle.left = `${targetLeft - gutter.position}px`
      highlighterStyle.width = `${targetWidth + gutter.size}px`
      highlighterStyle.height = `${targetHeight + gutter.size}px`
    })

    lastFocusedCoords.value = String(targetTop + targetLeft + targetWidth + targetHeight)
    lastFocused.value = focusedElement
  }
  const disable = () => {
    if (!isKeyboardModality.value) return
    if (!highlighterRef.value) return

    const highlighterStyle = highlighterRef.value.style

    isKeyboardModality.value = false
    highlighterStyle.width = '0'
    highlighterStyle.height = '0'
    isVisible.value = false
    lastFocused.value = null
  }
  const handleFocus = (): void => {
    if (!isVisible.value || !(document.activeElement instanceof HTMLElement)) {
      return
    }
    const focusedElement = document.activeElement as FocusableElement

    if (!isValidTarget(focusedElement) || (isTextInput(focusedElement) && !isKeyboardModality.value)) {
      return
    }

    detectSpeedUp()
    moveTo(focusedElement)
  }
  const enable = () => {
    if (isKeyboardModality.value) return

    isKeyboardModality.value = true
    isVisible.value = true
    handleFocus()
  }
  const handleClick = (event: MouseEvent): void => {
    if (isVisible.value && getIsRealMouseEvent(event)) {
      disable()
    }
  }
  const handleKeyup = (event: KeyboardEvent): void => {
    if (event.key === AsfKeyValue.TAB) {
      enable()
    }
    if (event.key === AsfKeyValue.ENTER) {
      if (lastFocused.value) {
        timeout(() => moveTo(lastFocused.value as FocusableElement), 300)
      } else {
        disable()
      }
    }
    if (event.key === AsfKeyValue.SPACE && lastFocused.value) {
      timeout(() => moveTo(lastFocused.value as FocusableElement), 300)
    }
  }
  const handleKeydown = (event: KeyboardEvent): void => {
    if (event.key === AsfKeyValue.ENTER) {
      const target = event.target as HTMLElement

      if (!isFocusHighlighterEnabled(target)) {
        disable()
      }
    }
  }
  const handleResize = () => {
    if (isVisible.value) {
      moveTo(lastFocused.value as FocusableElement)
    }
  }

  watch(
    () => isVisible.value && isKeyboardModality.value,
    (value) => {
      if (value) {
        highlighterRef.value?.classList.add('is-visible')
      } else {
        highlighterRef.value?.classList.remove('is-visible')
      }
    }
  )

  watch(
    () => isSpeedUp.value,
    (value) => {
      if (value) {
        highlighterRef.value?.classList.add('is-speed-up')
      } else {
        highlighterRef.value?.classList.remove('is-speed-up')
      }
    }
  )

  onBeforeMount(() => {
    window.removeEventListener('resize', handleResize)
    EventBus.off('disable:highlighter', disable)
    EventBus.off('enable:highlighter', enable)
  })

  onMounted(() => {
    window.addEventListener('resize', handleResize)
    EventBus.on('disable:highlighter', disable)
    EventBus.on('enable:highlighter', enable)

    if (pageLayoutRef.value) {
      pageLayoutRef.value.addEventListener('click', handleClick)
      pageLayoutRef.value.addEventListener('focusin', handleFocus)
      pageLayoutRef.value.addEventListener('keyup', handleKeyup)
      pageLayoutRef.value.addEventListener('keydown', handleKeydown)
    }
  })

  onBeforeUnmount(() => {
    if (pageLayoutRef.value) {
      pageLayoutRef.value.removeEventListener('click', handleClick)
      pageLayoutRef.value.removeEventListener('focusin', handleFocus)
      pageLayoutRef.value.removeEventListener('keyup', handleKeyup)
      pageLayoutRef.value.removeEventListener('keydown', handleKeydown)
    }
  })
}
