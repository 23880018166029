<script setup lang="ts">
import type { CheckoutFooterNavigationProps } from '../../types'

defineProps<CheckoutFooterNavigationProps>()
const { contentId, isContentModalOpened, openContentModal, closeContentModal } = useContentModal()
</script>
<template>
  <div>
    <AsfList class="asf-checkout-footer__navigation" role="list">
      <AsfListItem v-for="(link, idx) in links" :key="idx" class="asf-checkout-footer__navigation-link">
        <ContentLink v-bind="link" class="asf-link-secondary" :is-anchor="true" @click="openContentModal" />
      </AsfListItem>
    </AsfList>
    <ContentModal v-if="contentId" :id="contentId" :visible="isContentModalOpened" @close:modal="closeContentModal" />
  </div>
</template>
